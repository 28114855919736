import './../../../style/solutionsPopup/Test1.css';
import { useTranslation } from "react-i18next";

function Test1() {

    // internationalization
    const {t} = useTranslation();

    return (
        <div>
Laptop X-Tech Pro 15 – Wydajność i Styl w Jednym

Laptop X-Tech Pro 15 to nowoczesne urządzenie stworzone z myślą o użytkownikach, którzy cenią sobie wydajność, mobilność i elegancki design. Wyposażony w najnowszej generacji procesor, szybki dysk SSD i wysokiej jakości ekran, doskonale sprawdzi się zarówno w pracy biurowej, jak i podczas rozrywki.
Moc Obliczeniowa, Która Robi Różnicę

W sercu urządzenia znajduje się procesor Intel Core i7 13. generacji, zapewniający płynność działania nawet w najbardziej wymagających zadaniach. 16 GB pamięci RAM umożliwia bezproblemową pracę na wielu aplikacjach jednocześnie, a 512 GB SSD gwarantuje błyskawiczne uruchamianie systemu i programów.
Wyświetlacz, Który Zachwyca

15,6-calowy ekran Full HD IPS oferuje wyjątkową jakość obrazu, szerokie kąty widzenia oraz realistyczne odwzorowanie kolorów. Dzięki cienkim ramkom laptop wygląda nowocześnie i stylowo, a technologia Eye Care redukuje emisję niebieskiego światła, chroniąc Twój wzrok.
        </div>
    )}

export default Test1;
