/* imports */
import './../style/homepage.css';
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

// png and video
import homepageVideo from './../video/homepage-video.mp4';
import grayMan from './../img/homepage/gray-man-walking.jpg'

/* slider */
import slidePmp from './../img/SliderHomepage/slide-pmp.png';
import slideMinistry from './../img/SliderHomepage/slide-ministry.png';
import slideReset from './../img/SliderHomepage/slide-reset.png';
import slideSap from './../img/SliderHomepage/slide-sap.png';


/* tiles */
import tileSVG1 from './../img/homepage/tiles/tile_img_1.svg';
import tileSVG2 from './../img/homepage/tiles/tile_img_2.svg';
import tileSVG3 from './../img/homepage/tiles/tile_img_3.svg';


import ImageSlider from '../components/fragments/ImageSlider'

/* end */

function Homepage() {

    // internationalization
    const {t} = useTranslation();

    // scroll-driven animations
    useEffect(() => {

        // Select all hidden elements
        const elements = document.querySelectorAll(".hidden");

        // Add show class if its intersecting
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                entry.target.classList.toggle("show", entry.isIntersecting);
                if (entry.isIntersecting) observer.unobserve(entry.target);
            });
        });

        // Observe each hidden element
        elements.forEach((el) => {
            observer.observe(el);
        });

    }, []);


    // slider
    const slides = [
        {img: slideSap, title: "sap", textLineOne: t('homepage.slider.sap.line-1'), textLineTwo: t("homepage.slider.sap.line-2")},
        {img: slideMinistry, title: "ministry", textLineOne: t("homepage.slider.ministry.line-1"), textLineTwo: t("homepage.slider.ministry.line-2")},
        {img: slidePmp, title: "pmp", textLineOne: t("homepage.slider.pmp.line-1"), textLineTwo: t("homepage.slider.pmp.line-2")},
        {img: slideReset, title: "reset", textLineOne: t("homepage.slider.reset.line-1"), textLineTwo: t("homepage.slider.reset.line-2")}
    ];

    // scroll to top
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className="homepage">

            {/* spacing for mobile */}
            <div className="homepage-mobile-spacing"/>

            {/* background video */}
            <div className="homepage-video-container">
                <video className="homepage-video" autoPlay loop muted>
                    <source src={homepageVideo} type="video/mp4"/>
                </video>
                <div className="homepage-video-overlay">
                    <div className="homepage-video-overlay-text-container">
                        <h1 className="homepage-video-overlay-company-name">
                            HUMANE <h1 className="and-sign">&</h1> BUSINESS PROGRESS
                        </h1>
                        <h2 className="homepage-video-overlay-incentive">
                            {t("homepage.video-overlay.incentive")}
                        </h2>
                    </div>
                </div>
            </div>

            {/* tiles section */}
            <div className="homepage-tiles-section">
                <div className="homepage-tiles-section-top-left homepage-tile">
                    <h3 className="hidden">
                        {t("homepage.tiles.question")}
                    </h3>
                </div>
                <div className="homepage-tiles-section-top-right homepage-tile">
                    <div className="homepage-tiles-inner-section">
                        <div className="homepage-tiles-inner-container hidden">
                            <img className="homepage-tiles-svg" src={tileSVG1} alt=""/>
                            <div className="homepage-tiles-text-container">
                                <h4 className="homepage-tiles-title">
                                    {t("homepage.tiles.tile-top-right.title")}
                                </h4>
                                <div className="homepage-tiles-text">
                                    {t("homepage.tiles.tile-top-right.text")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homepage-tiles-section-bot-left homepage-tile">
                    <div className="homepage-tiles-inner-section">
                        <div className="homepage-tiles-inner-container hidden">
                            <img className="homepage-tiles-svg" src={tileSVG2} alt=""/>
                            <div className="homepage-tiles-text-container">
                                <h4 className="homepage-tiles-title">
                                    {t("homepage.tiles.tile-bot-left.title")}
                                </h4>
                                <div className="homepage-tiles-text">
                                    {t("homepage.tiles.tile-bot-left.text")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homepage-tiles-section-bot-right homepage-tile">
                    <div className="homepage-tiles-inner-section">
                        <div className="homepage-tiles-inner-container hidden">
                            <img className="homepage-tiles-svg" src={tileSVG3} alt=""/>
                            <div className="homepage-tiles-text-container">
                                <h4 className="homepage-tiles-title">
                                    {t("homepage.tiles.tile-bot-right.title")}
                                </h4>
                                <div className="homepage-tiles-text">
                                    {t("homepage.tiles.tile-bot-right.text")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* two sides section */}
            <div className="homepage-two-sides-section">

                <div className="homepage-two-sides-section-img-container">
                    <img src={grayMan} alt="gray man img"/>
                    <div className="homepage-two-sides-section-img-cover"/>
                </div>

                <div className="homepage-two-sides-section-text-container">

                    <div className="homepage-two-sides-section-text-inner-container">
                        <div className="homepage-two-sides-section-text-title">
                            <h3 className="hidden">
                                {t("homepage.two-sides-section.block-1.title")}
                            </h3>
                        </div>
                        <div className="homepage-two-sides-section-text-separator-container">
                            <div className="hidden">
                                <div className="homepage-two-sides-section-text-separator"/>
                            </div>
                        </div>
                        <div className="homepage-two-sides-section-text">
                            <div className="hidden">
                                {t("homepage.two-sides-section.block-1.text")}
                            </div>
                        </div>
                    </div>

                    <div className="homepage-two-sides-section-text-inner-container">
                        <div className="homepage-two-sides-section-text-title">
                            <h3 className="hidden">
                                {t("homepage.two-sides-section.block-2.title")}
                            </h3>
                        </div>
                        <div className="homepage-two-sides-section-text-separator-container">
                            <div className="hidden">
                                <div className="homepage-two-sides-section-text-separator"/>
                            </div>
                        </div>
                        <div className="homepage-two-sides-section-text">
                            <div className="hidden">
                                {t("homepage.two-sides-section.block-2.text")}
                            </div>
                        </div>
                    </div>

                    <div className="homepage-two-sides-section-text-inner-container">
                        <div className="homepage-two-sides-section-text-title">
                            <h3 className="hidden">
                                {t("homepage.two-sides-section.block-3.title")}
                            </h3>
                        </div>
                        <div className="homepage-two-sides-section-text-separator-container">
                            <div className="hidden">
                                <div className="homepage-two-sides-section-text-separator"/>
                            </div>
                        </div>
                        <div className="homepage-two-sides-section-text">
                            <div className="hidden">
                                {t("homepage.two-sides-section.block-3.text")}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* slider */}
            <div className="homepage-slider-section">
                <h2 className="homepage-slider-title">
                    {t("homepage.slider.title")}
                </h2>
                {<ImageSlider slides={slides}/>}
            </div>

            {/* spacing */}
            <div className="spacing"/>

        </div>
    );
}

export default Homepage;
