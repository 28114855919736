import {useCallback, useEffect, useRef, useState} from "react";
import "./../../style/imageSlider.css"

function ImageSlider({slides}) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(slides.length - 1);
    const [previousAnimation, setPreviousAnimation] = useState("slider-previous-img");
    const [currentAnimation, setCurrentAnimation] = useState("slider-img slider-current-img");
    const timerRef = useRef(null);
    const slideTime = 7000000;

    const goToPrevious = () => {
        setPreviousIndex(currentIndex);
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const goToNext = useCallback(() => {
        setPreviousIndex(currentIndex);
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }, [currentIndex, slides.length]);

    const goToSlide = (slideIndex) => {
        setPreviousIndex(currentIndex);
        setCurrentIndex(slideIndex);
    };

    // auto-play
    useEffect(() => {
        if (timerRef.current){
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            goToNext();
        }, slideTime);

        return () => clearTimeout(timerRef.current);
    }, [goToNext]);


    // change of animation direction
    useEffect(() => {
        if (currentIndex !== previousIndex){
            if (currentIndex > previousIndex) {
                setCurrentAnimation("slider-current-img slider-slide-in-right slider-img-container");
                setPreviousAnimation("slider-previous-img slider-slide-out-left slider-img-container");
            }
            else if (currentIndex < previousIndex) {
                setCurrentAnimation("slider-current-img slider-slide-in-left slider-img-container");
                setPreviousAnimation("slider-previous-img slider-slide-out-right slider-img-container");
            }
        }
    }, [goToNext, goToPrevious, goToSlide]);


    // change of dots appearance
    useEffect(() => {
        for (let i = 0; i < slides.length; i++){
            let dotElement = document.getElementById("dot-" + i);
            if (i === currentIndex) dotElement.className = "slider-dot slider-active-dot";
            else dotElement.className = "slider-dot";
        }
    }, [goToNext, goToPrevious, goToSlide]);


    return (
        <div className="slider-section">
            <div className="slider">
                <div id="sliderPreviousImg slider-img-container" className={previousAnimation} key={Math.random()}>
                    <img className="slider-img" src={slides[previousIndex].img} alt="slider"/>
                    <h2 className="slider-text">
                        {slides[previousIndex].textLineOne}
                    </h2>
                    <h2 className="slider-text">
                        {slides[previousIndex].textLineTwo}
                    </h2>
                </div>
                <div id="sliderCurrentImg" className={currentAnimation} key={Math.random()}>
                    <img className="slider-img"  src={slides[currentIndex].img} alt="slider" />
                    <h2 className="slider-text">
                        {slides[currentIndex].textLineOne}
                    </h2>
                    <h2 className="slider-text">
                        {slides[currentIndex].textLineTwo}
                    </h2>
                </div>
                <i id="sliderLeftArrow" className="slider-left-arrow arrow left show-on-slider-hover" onClick={() => {
                    goToPrevious()
                }}/>
                <i id="sliderRightArrow" className="slider-right-arrow arrow right show-on-slider-hover" onClick={ () => {
                    goToNext()
                }}/>
                <div className="slider-dots-outer-container show-on-slider-hover">
                    <div className="slider-dots-container">
                        {slides.map((slide, slideIndex) => (
                            <div
                                id={"dot-" + slideIndex}
                                key={slideIndex}
                                className="slider-dot"
                                onClick={() => goToSlide(slideIndex)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageSlider
