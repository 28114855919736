import './../style/solutions.css';
import { useTranslation } from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {useScrollPosition} from "../hooks/useScrollPosition";

import hexagonFront from "../img/solutions/hexagonFront.svg";
import hexagonFrontDW from "../img/solutions/hexagonFrontDW.svg";
import hexagonBack from "../img/solutions/hexagonBack.svg";

/* Hexagon icons */
import iconCFIN from "../img/solutions/Icons/IkonaCFIN_2_biala.svg";
import iconDW from "../img/solutions/Icons/IkonaDATAWAREHOUSE_2_biala.svg";
import iconEDI from "../img/solutions/Icons/IkonaEDI_1_biala.svg";
import iconHFM from "../img/solutions/Icons/IkonaHFM_1_biala.svg";
import iconBPN from "../img/solutions/Icons/Business_Partner_Notification.svg";
import iconIMD from "../img/solutions/Icons/Item_Master_Data.svg";
import iconO2C from "../img/solutions/Icons/O2C_Integration_Proces.svg";
import iconExcell from "../img/solutions/Icons/Import_From_excel-02.svg";
import iconIntegrations from "../img/solutions/Icons/R2_Integrations-02.svg";
import iconAPons from "../img/solutions/Icons/APons_Custom-02.svg";

/* Import pop-up window versions */
import Test1 from "../components/fragments/solutions/Test1";
import Test2 from "../components/fragments/solutions/Test2";


import Hexagons from './fragments/Hexagons';


function Solutions() {

    // Pop-ups
    const popups = [
        {Index: 1, Title: "Test1", Content: Test1},
        {Index: 2, Title: "Test2", Content: Test2},
    ];

    // Variables
    const [popup, setPopup] = useState(popups.find(p => p.Index === 1));

    // internationalization
    const {t} = useTranslation();

    // scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // hexagon row transform
    const hexagonRows = document.querySelectorAll('.solutions-hexagon-row');

    hexagonRows.forEach((hexagonRow, index) => {
       hexagonRow.style.transform = `translate(0, ${(index - 1) * -25}%)`
    });

    // Choose popup and show it
    const choosePopup = (index) => {
        setPopup(popups.find(p => p.Index === index));
        changePopupVisibility();
    };

    // Change popup visibility
    const changePopupVisibility = () => {
        document.querySelectorAll(".solutions-popup-container")
            .forEach(p => p.classList
                .toggle("solutions-popup-container-hidden"));
    };

    // Create hexagons
    const hexagons = [
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse2']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
        {index: 1, name: "test1", img: iconDW, text: ['Data', 'Warehouse']},
    ];



    return (
        <div id="solutions">

            {/* tmp added */}
            <div class="solutions-top-spacing"/>

            {/* Main section */}
            <div className="solutions-main-section">

                {/* Pop-up window */}
                <div className="solutions-popup-container solutions-popup-container-hidden">
                    <div className="solutions-popup-inner-container">
                        <div className="solutions-popup-top-bar">
                            <div className="solutions-popup-top-bar-title">
                                {popup.Title}
                            </div>
                            <div className="solutions-popup-top-bar-exit-container">
                                <div className="solutions-popup-top-bar-exit" onClick={() => changePopupVisibility()}>
                                    X
                                </div>
                            </div>
                        </div>

                        <div className="solutions-popup-main-frame">
                            {popup.Content}
                        </div>
                    </div>
                </div>

                {/* Row 1 */}
                <div className="solutions-hexagon-row">
                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>

                    {/* onClick={() => choosePopup(1) */}
                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconBPN} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Customer <br/> Service <br/> Notification</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconCFIN} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Central <br/> Finance <br/> Reporting</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconAPons} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Custom <br/> AddOns</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Row 2 */}
                <div className="solutions-hexagon-row">
                    <div className="solutions-hexagon solutions-hexagon-clipped-left">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconIMD} alt="icon"/>
                                    </div>
                                </div>
                                <p></p>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Central <br/> Master Data <br/> Management</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFrontDW} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconDW} alt="icon"/>
                                    </div>
                                </div>
                                <p></p>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Data<br/>Warehouse</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconExcell} alt="icon"/>
                                    </div>
                                </div>
                                <p></p>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Imports<br/>from<br/>Excel</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconEDI} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>EDIs</h1>
                            </div>

                        </div>
                    </div>
                    <div className="solutions-hexagon solutions-hexagon-clipped-right">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>

                </div>

                {/* Row 3 */}
                <div className="solutions-hexagon-row">
                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconO2C} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Intercompany <br/> Solution</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconHFM} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>Hyperion<br/>Financial<br/>Management<br/>System</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                    <div className="solutions-hexagon-icon">
                                        <img src={iconIntegrations} alt="icon"/>
                                    </div>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <h1>HR<br/>Integrations</h1>
                            </div>

                        </div>
                    </div>

                    <div className="solutions-hexagon">
                        <div className="solutions-hexagon-inner solutions-hexagon-noanim">

                            <div className="solutions-hexagon-front">
                                <div className="solutions-hexagon-front-inner">
                                    <img src={hexagonFront} alt="Front"/>
                                </div>
                            </div>

                            <div className="solutions-hexagon-back">
                                <div className="solutions-hexagon-back-inner">
                                    <img src={hexagonBack} alt="Front"/>
                                </div>
                                <p>Back</p>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

{/*
            <div style={{height: 1000+'px'}}/>

            <div>
                <Hexagons hexagonContent={hexagons}/>
            </div>
        */}

        </div>
    );
}

export default Solutions;
